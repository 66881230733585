import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${rem(16)} ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  margin-bottom: ${rem(10)};
  ${media.tabletLandscape} {
    padding: ${rem(16)} ${rem(32)};
  }
`;

export const SearchFieldwrapper = styled.div`
  padding: ${rem(20)} 0 0;
  margin-bottom: ${rem(10)};
`;

export const MapHeaderWrapper = styled.div`
  padding: ${rem(20)};
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  ${media.desktop} {
    padding-right: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const NearMeButtonWrapper = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

export const AutocompleteWrapper = styled.div`
  max-width: 100%;
  position: relative;
`;

export const MapsWrapper = styled.div`
  margin-top: 5px;
`;

export const HorizontalBar = styled.div`
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.colors.colorPampas};
`;

export const OpeningHours = styled.div`
  margin: ${rem(8)} 0 ${rem(16)};
`;

export const CustomCloseButton = styled.div`
  position: absolute;
  top: ${rem(6)};
  right: ${rem(6)};
  cursor: pointer;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  padding: ${rem(6)};
`;

export const LinkAreaWrapper = styled.div`
  margin-top: ${rem(20)};
  text-decoration: underline;

  button {
    padding: 0;
    justify-content: flex-start;
  }
`;

export const InfoWindowContent = styled.div`
  padding: ${rem(8)};
`;
