import Heading from '@components/atoms/Heading';
import { ParagraphMessagecontainer, StyledParagraphText } from './ParagraphMessage.styled';
import useTranslation from 'next-translate/useTranslation';

const ParagraphMessage = () => {
  const { t } = useTranslation('store');
  return (
    <ParagraphMessagecontainer>
      <Heading type="h3" size="medium">
        {t('welcomeMessage->header')}
      </Heading>
      <StyledParagraphText> {t('welcomeMessage->text')}</StyledParagraphText>
      <StyledParagraphText> {t('welcomeMessage->footer')}</StyledParagraphText>
    </ParagraphMessagecontainer>
  );
};

export default ParagraphMessage;
