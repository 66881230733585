import Icon from '@components/atoms/Icon';
import { IconType } from '@components/atoms/Icon/Icon';
import { FlexLinkArea, StoreInfoText } from './StoreInfo.styles';

interface Props {
  href: string;
  icon: IconType;
  text: string;
}
const LinkWithIcon = ({ href, text, icon }: Props) => {
  return (
    <FlexLinkArea target={'_blank'} href={href}>
      <Icon icon={icon} />
      <StoreInfoText weight="medium">{text}</StoreInfoText>
    </FlexLinkArea>
  );
};

export default LinkWithIcon;
