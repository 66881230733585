import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import CmsContentSlot from '@components/atoms/CmsContentSlot/CmsContentSlot';
import { Stack } from '@components/atoms/Stack';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import { ReactNode } from 'react';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  slotList: string[];
}

const CategoryTemplate = ({ cmsPage, slotList, children }: Props) => {
  const { CategoryMainContent } = collectSlots(cmsPage.slots, slotList);
  const categoryMainContentList = CategoryMainContent?.component || [];
  return (
    <Stack $space="smallMedium">
      {children}
      <CmsContentSlot
        slotData={CategoryMainContent}
        id="Content-CategoryMainContent-hemkop-CategoryTemplate"
        data-testid="Content-CategoryMainContent-hemkop-CategoryTemplate"
      >
        <Stack $space="small">
          {categoryMainContentList.map((component: CmsComponent, index) => (
            <CmsComponent
              key={component.uuid}
              component={component}
              slotName="CategoryMainContent"
              slotPosition={index}
            />
          ))}
        </Stack>
      </CmsContentSlot>
    </Stack>
  );
};

export default CategoryTemplate;
