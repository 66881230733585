import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { GapLessContainer } from '../../../StorePage/StoreSideMenu/StoreSideMenu.styles';
import { StorePageInfo } from '../../useStoreInfoCmsData';

const ServiceContent = ({ storeInfo }: { storeInfo: StorePageInfo }) => {
  return (
    <GapLessContainer>
      {storeInfo.storeFeatures.map((feature) => (
        <Paragraph key={feature} size="md">
          {feature}
        </Paragraph>
      ))}
    </GapLessContainer>
  );
};

export default ServiceContent;
