import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { StorePageInfo } from '../../useStoreInfoCmsData';
import IconWithText from '@features/recipes/RecipeDetailsPage/IconWithText';
import { formatPostalCode } from '@helpers/address';
import IconDelivery from '@public/icons/regularIcons/icon-delivery.svg';
import IconPin from '@public/icons/regularIcons/icon-pin.svg';
import useTranslation from 'next-translate/useTranslation';
import { ColumnContainer, ContentContainer, StyledLinkArea } from '../StoreSideMenu.styles';

const LocationContent = ({ storeInfo }: { storeInfo: StorePageInfo }) => {
  const { t } = useTranslation('oldstore');
  const { streetAddress, postalCode, town, mapUrl, directionsUrl } = storeInfo;
  const postalAddress = formatPostalCode(postalCode, town);

  return (
    <ContentContainer>
      <div>
        <Paragraph size="md">{streetAddress}</Paragraph>
        <Paragraph size="md">{postalAddress}</Paragraph>
      </div>
      <ColumnContainer>
        {mapUrl && (
          <StyledLinkArea target={'_blank'} href={mapUrl}>
            <IconWithText icon={IconPin} textSize={'md'} text={t('store->location->onMap')} />
          </StyledLinkArea>
        )}
        {directionsUrl && (
          <StyledLinkArea target={'_blank'} href={directionsUrl}>
            <IconWithText icon={IconDelivery} textSize={'md'} text={t('store->location->directions')} />
          </StyledLinkArea>
        )}
      </ColumnContainer>
    </ContentContainer>
  );
};

export default LocationContent;
