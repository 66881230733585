import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { StorePageInfo } from '../../../StorePage/useStoreInfoCmsData';
import useTranslation from 'next-translate/useTranslation';
import { Fragment } from 'react';
import { GapLessContainer, OpeningHoursWrapper, Title } from '../StoreSideMenu.styles';

const OpeningHours = ({ name, openToClosingHours }: { name?: string; openToClosingHours?: string }) => {
  if (!name) return null;
  return (
    <OpeningHoursWrapper>
      <Paragraph size="md">{name}</Paragraph>
      {openToClosingHours && <Paragraph size="md">{openToClosingHours}</Paragraph>}
    </OpeningHoursWrapper>
  );
};

const OpeningHoursContent = ({ storeInfo }: { storeInfo: StorePageInfo }) => {
  const { openingDays, storeFeatureOpeningDays, specialOpeningDays } = storeInfo;
  const { t } = useTranslation('oldstore');

  return (
    <GapLessContainer>
      {openingDays?.map(({ name, openToClosingHours }) => {
        return <OpeningHours key={openToClosingHours} name={name} openToClosingHours={openToClosingHours} />;
      })}
      {storeFeatureOpeningDays?.map(({ storeFeature, openingHours }) => (
        <Fragment key={storeFeature}>
          <Title size={'md'} bold="bold">
            {storeFeature}
          </Title>
          {openingHours.map(({ name, openToClosingHours }) => (
            <OpeningHours key={name} name={name} openToClosingHours={openToClosingHours} />
          ))}
        </Fragment>
      ))}
      {!!specialOpeningDays?.length && (
        <Fragment key={'specialOpeningHours'}>
          <Title bold="bold">{t('store->specialOpeningHours')}</Title>
          {specialOpeningDays?.map(({ name, openToClosingHours }) => (
            <OpeningHours key={`specialOpeningHours-${name}`} name={name} openToClosingHours={openToClosingHours} />
          ))}
        </Fragment>
      )}
    </GapLessContainer>
  );
};

export default OpeningHoursContent;
