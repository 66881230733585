import { FieldValues, Path, UseFormRegister, UseFormWatch } from 'react-hook-form';
import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { debouncedfetchAddressSuggestions } from '@components/molecules/DeliveryHomeSelector/utils';
import { concatGepositAddress } from '@helpers/geposit';
import axios from 'axios';
import useListNavigationKeyHandler from '@hooks/useListNavigationKeyHandler';
import InputField from '@components/atoms/InputField';
import { DEFAULT_REQUIRED } from '@constants/formValidation';
import { StyledAutocomplete } from '@components/organisms/GepositAddress/GepositAddress.styles';

export interface Props<FormData extends FieldValues> {
  watch: UseFormWatch<FormData>;
  setValue: any; //kanske ska bort
  setAutocompleteIsOpen: (arg0: boolean) => void;
  autoCompleteIsOpen: boolean;

  streetId: Path<FormData>;
  register: UseFormRegister<FormData>;
  handleDisableSubmit?: () => void;
  onClickAutoComplete: (s: Suggestion) => void;
  getError?: (arg: keyof FormData) => string | undefined;
  label: string;
  mandatory?: boolean;
}
const AddressFieldWithSuggestions = <FormData extends FieldValues>({
  watch,
  streetId,
  register,
  setAutocompleteIsOpen,
  onClickAutoComplete,
  getError,
  label,
  mandatory = false,
  autoCompleteIsOpen,
}: Props<FormData>) => {
  const [suggestionIndex, setSuggestionIndex] = useState(-1);
  const [suggestions, setSuggestions] = useState<Array<GepositAddressState>>([]);

  const streetHasValue = !!watch(streetId);

  const source = axios.CancelToken.source();
  const cancelToken = source.token;

  const getSuggestions = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > 2) {
      const res: GepositAddress[] = await debouncedfetchAddressSuggestions(e.currentTarget.value, cancelToken);
      const addTermToSuggestions = res.map((s) => {
        const term = concatGepositAddress(s, true);
        return {
          ...s,
          term,
        };
      });
      setAutocompleteIsOpen(true);
      setSuggestions(addTermToSuggestions);
    }
  };

  const handleClear = () => {
    setSuggestionIndex(-1);
  };

  const handleKeyboardSubmit = () => {
    if (suggestions.length > 0 && suggestionIndex > -1 && autoCompleteIsOpen && streetHasValue) {
      const targetSuggestion = suggestions[suggestionIndex];
      onClickAutoComplete(targetSuggestion);
      setAutocompleteIsOpen(false);
      handleClear();
    }
  };
  const { listNavigationKeyHandler } = useListNavigationKeyHandler({
    onKeyEnter: handleKeyboardSubmit,
    onKeyEscape: handleClear,
    currentIndex: suggestionIndex,
    setCurrentIndex: setSuggestionIndex,
    items: suggestions,
  });
  const onKeyUpStreet = (fieldEvent: KeyboardEvent<HTMLInputElement>) => {
    if (autoCompleteIsOpen) {
      listNavigationKeyHandler(fieldEvent);
      if (fieldEvent.code === 'Enter') {
        fieldEvent.currentTarget.blur();
        setAutocompleteIsOpen(false);
        handleClear();
      }
    }
  };

  const onClick = (suggestion: Suggestion) => {
    onClickAutoComplete(suggestion);
    setAutocompleteIsOpen(false);
  };

  const rules = mandatory ? { required: DEFAULT_REQUIRED } : undefined;

  return (
    <>
      <InputField
        name={streetId}
        id={streetId}
        register={register}
        isActive={streetHasValue}
        onChange={getSuggestions}
        rules={rules}
        error={getError && getError(streetId)}
        label={label}
        setValueAs={(street) => street.trim()}
        disableAutoComplete
        onKeyUp={onKeyUpStreet}
        data-testid={`${streetId}-field`}
      />
      {autoCompleteIsOpen && streetHasValue && (
        <StyledAutocomplete
          searchTerm={watch(streetId)}
          suggestions={suggestions}
          onClick={(__, suggestion) => suggestion && onClick(suggestion)}
          suggestionIndex={suggestionIndex}
          scrollItem
          boldSearchTermSubstring
          highlightOnHover
        />
      )}
    </>
  );
};

export default AddressFieldWithSuggestions;
