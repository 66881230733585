import { PageWithSlots } from '@api';
import LayoutPageContainer from '@components/Layout/LayoutPageContainer';
import CmsTemplate from '@components/cmsComponents/CmsTemplate';
import useSmartEdit, { SmartEditContext } from '@hooks/useSmartEdit';
import React, { useEffect } from 'react';

interface Props {
  children?: React.ReactNode;
  cmsPage?: PageWithSlots;
}
const Page = ({ children, cmsPage }: Props) => {
  const { isSmartEditEnabled, toggleBodyClasses } = useSmartEdit();
  useEffect(() => {
    cmsPage &&
      isSmartEditEnabled &&
      toggleBodyClasses(isSmartEditEnabled, cmsPage.uid, cmsPage.uuid, cmsPage.catalogVersionUuid);
  }, [cmsPage, isSmartEditEnabled, toggleBodyClasses]);
  return (
    <SmartEditContext.Provider value={{ catalogVersionUuid: cmsPage?.catalogVersionUuid }}>
      <LayoutPageContainer>
        {!!cmsPage ? <CmsTemplate cmsPage={cmsPage}>{children}</CmsTemplate> : children}
      </LayoutPageContainer>
    </SmartEditContext.Provider>
  );
};

export default Page;
