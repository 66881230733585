import { capitalizeFirst } from '../../../helpers/string';
import { StoreInfoComponentDataType } from '../types';
import { getOpeningDays } from './helpers';

export interface StructuredLocalBusiness {
  '@context': 'https://schema.org';
  '@type': 'LocalBusiness';
  name: string;
  address: { [key: string]: string };
  priceRange?: string;
  telephone?: string;
  url?: string;
  image?: string;
  openingHours?: string[];
}
export const hemkopLogoType = 'https://hemkop.se/images/hemkop_logo_red.svg';
export const hemkopUrl = 'https://www.hemkop.se/';
export const convertLocalBusiness = (storePageInfo: StoreInfoComponentDataType): StructuredLocalBusiness => {
  const { address, displayName = '' } = storePageInfo.store || {};
  const { postalCode = '', line1: streetAddress = '', town = '', phone = '' } = address || {};

  const openingHours = getOpeningDays(
    (key) => capitalizeFirst(key.slice(0, 2).toLowerCase()),
    storePageInfo.mergedOpeningDays
  ).map((item) => `${item.name} ${item.openToClosingHours}`);

  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: displayName,

    address: {
      '@type': 'PostalAddress',
      streetAddress: streetAddress,
      postalCode: postalCode,
      addressLocality: town,
    },
    openingHours,
    image: hemkopLogoType,
    telephone: phone,
    url: hemkopUrl,
  };
};
