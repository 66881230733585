import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const StorePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  ${media.desktop} {
    flex-direction: row;
  }
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageAndMapWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  ${media.tabletPortrait} {
    flex-direction: row;
  }
`;

export const Section = styled.div`
  flex-basis: 50%;
  border-radius: ${rem(16)};
  overflow: hidden;
`;
