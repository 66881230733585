import { StorePageInfo } from '../../useStoreInfoCmsData';
import IconWithText from '@features/recipes/RecipeDetailsPage/IconWithText';
import IconMail from '@public/icons/regularIcons/icon-mail.svg';
import IconPhone from '@public/icons/regularIcons/icon-phone.svg';
import useTranslation from 'next-translate/useTranslation';
import { ContentContainer, StyledLinkArea } from '../StoreSideMenu.styles';

const ContactContent = ({ storeInfo }: { storeInfo: StorePageInfo }) => {
  const { t } = useTranslation('oldstore');
  const { mapUrl, directionsUrl, phoneNumber, eMail } = storeInfo;

  return (
    <ContentContainer>
      {mapUrl && (
        <StyledLinkArea target={'_blank'} href={`tel:${phoneNumber}`}>
          <IconWithText icon={IconPhone} textSize={'md'} text={phoneNumber} />
        </StyledLinkArea>
      )}
      {/* TODO: Why in the name of something should e-mail be underlined??? */}
      {directionsUrl && (
        <StyledLinkArea target={'_blank'} href={`mailto:${eMail}`}>
          <IconWithText icon={IconMail} textSize={'md'} text={t('store->contact->eMail')} />
        </StyledLinkArea>
      )}
    </ContentContainer>
  );
};

export default ContactContent;
