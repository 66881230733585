import { Stack } from '@components/atoms/Stack';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';
import { panelContentMaxWidth } from './StoreHeroContainer/StoreHeroContainer.styles';

export const ContentContainer = styled(Stack).attrs({ $space: 'nano' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: ${rem((panelContentMaxWidth * 3) / 5)};

  ${media.largeMobile} {
    max-width: ${rem(panelContentMaxWidth)};
    align-items: flex-start;
    text-align: start;
  }
`;

export const Welcome = styled.div`
  font-family: ${({ theme }) => theme.typography.fontCaveat};
  font-size: ${rem(24)};

  ${media.largeMobile} {
    font-size: ${rem(28)};
  }
`;

export const OpenHours = styled.div`
  padding-top: ${rem(12)};
  display: flex;
  gap: ${rem(4)};
  color: ${({ theme }) => theme.colors.colorPrimary};
  font-family: ${({ theme }) => theme.typography.fontSans};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  font-size: ${rem(18)};

  ${media.largeMobile} {
    font-size: ${rem(20)};
  }
`;
