import useCmsComponents from '@hooks/useCmsComponentGroup';
import useTranslation from 'next-translate/useTranslation';
import { getName, getOpeningDays, getOpeningHours, rewriteStoreFeatures } from './helpers';
import { OpeningHours, StoreFeatures, componentIsStoreInfoComponentDataType } from './types';

type OpenAndClosingHours = Array<{ name?: string; openToClosingHours?: string }>;

export interface StorePageInfo {
  storeName?: string;
  openingHoursMessageKey?: string;
  openingStoreMessageValue?: string;
  flyerUrl?: string;
  openingDays?: OpenAndClosingHours;
  specialOpeningDays?: OpenAndClosingHours;
  storeFeatureOpeningDays?: Array<{ storeFeature?: string; openingHours: OpenAndClosingHours }>;
  storeFeatures: Array<string>;
  streetAddress: string;
  postalCode: string;
  town: string;
  mapUrl?: string;
  directionsUrl?: string;
  phoneNumber: string;
  eMail: string;
}

const useStoreInfoCmsData = (storeId: string, componentIds: string): StorePageInfo => {
  const { data } = useCmsComponents(componentIds, storeId);
  const firstComponent = data?.[0];
  const storeInfo = componentIsStoreInfoComponentDataType(firstComponent) ? firstComponent : undefined;

  const { t } = useTranslation('oldstore');

  const {
    store,
    openingHoursMessageKey,
    openingStoreMessageValue,
    flyerUrl,
    showOpeningHours,
    showFeatureOpeningDays,
    mergedOpeningDays,
    specialOpeningDays,
    mergedFeatureOpeningDaysForWeek,
    storeFeatures,
  } = storeInfo || {};

  const rewrittenStoreFeatures: StoreFeatures = (storeFeatures && rewriteStoreFeatures(storeFeatures)) || {};

  const shouldShowOpeningHours = !!mergedOpeningDays && showOpeningHours === 'true';
  const shouldShowOpeningDays = !!mergedOpeningDays && showFeatureOpeningDays === 'true';
  const openingDays = shouldShowOpeningHours || shouldShowOpeningDays ? getOpeningDays(t, mergedOpeningDays) : undefined;

  const formattedSpecialOpeningDays =
    specialOpeningDays &&
    Object.values(specialOpeningDays).map(({ name1, name2, openingHours, closingHours }) => ({
      name: getName(t, name1, name2),
      openToClosingHours: getOpeningHours(openingHours, closingHours),
    }));

  const storeFeatureOpeningDays =
    mergedFeatureOpeningDaysForWeek &&
    Object.entries(mergedFeatureOpeningDaysForWeek)
      // filter out HIDDEN_STORE_FEATURES_IDS
      .filter(([key]) => rewrittenStoreFeatures[key])
      .map(([key, value]) => {
        return {
          storeFeature: rewrittenStoreFeatures && rewrittenStoreFeatures[key],
          openingHours: Object.values(value).map(({ name1, name2, openingHours, closingHours }: OpeningHours) => ({
            name: getName(t, name1, name2),
            openToClosingHours: getOpeningHours(openingHours, closingHours),
          })),
        };
      });

  const longitude = store?.geoPoint?.longitude;
  const latitude = store?.geoPoint?.latitude;
  const address = store?.address;

  const openingsHoursCopy =
    openingHoursMessageKey === 'store.openMessage.open' && openingStoreMessageValue === '-'
      ? 'storesearch->closedmessage'
      : openingHoursMessageKey?.replaceAll('.', '->');

  const openingStoreMessage = openingStoreMessageValue === '-' ? '' : openingStoreMessageValue;
  return {
    storeName: store?.displayName,
    openingHoursMessageKey: openingsHoursCopy,
    openingStoreMessageValue: openingStoreMessage,
    flyerUrl: flyerUrl,
    openingDays,
    specialOpeningDays: formattedSpecialOpeningDays,
    storeFeatureOpeningDays,
    storeFeatures: Object.values(rewrittenStoreFeatures),
    streetAddress: address?.line1 || '',
    postalCode: address?.postalCode || '',
    town: address?.town || '',
    mapUrl: longitude && latitude && `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
    directionsUrl:
      longitude && latitude && `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`,
    phoneNumber: address?.phone || '',
    eMail: address?.email || '',
  };
};

export default useStoreInfoCmsData;
