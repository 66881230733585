import { Stack } from '@components/atoms/Stack';
import { rem } from 'polished';
import styled from 'styled-components';

export const MapWrapper = styled.div`
  position: relative;
  min-height: ${rem(490)};
  overflow: hidden;
`;

export const MapImage = styled.img`
  height: ${rem(490)};
  width: 100%;
  object-fit: cover;
`;

export const MapInfoWindowStack = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: ${rem(16)};
  padding: ${rem(16)};
  width: 80%;
  position: absolute;
  top: ${rem(16)};
  left: ${rem(16)};
`;
