import Icon from '@components/atoms/Icon/Icon';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { ParagraphSizeType } from '@components/atoms/__DEPRECATED__/Paragraph/types';
import { IconWithTextWrapper } from '@features/recipes/RecipeDetailsPage/RecipeDetailsPage.styles';

interface Props {
  icon: React.FC;
  text: string;
  'data-testid'?: string;
  textSize?: ParagraphSizeType;
}

const IconWithText = ({ icon, text, 'data-testid': testId, textSize = 'lg' }: Props) => {
  return (
    <IconWithTextWrapper data-testid={testId}>
      <Icon icon={icon} data-testid={icon.name} />
      <Paragraph size={textSize}>{text}</Paragraph>
    </IconWithTextWrapper>
  );
};

export default IconWithText;
