import AccordionSection from '@components/atoms/__DEPRECATED__/AccordionSection';
import AccordionListItemBaseNewStyle from '@components/molecules/__DEPRECATED__/AccordionListItemBaseNewStyle';
import { ReactNode, useEffect, useState } from 'react';
import { AccordionTitle } from '../StoreSideMenu/StoreSideMenu.styles';
interface Props {
  title: string;
  content: ReactNode;
  expanded: boolean;
}
const Accordion = ({ title, content, expanded }: Props) => {
  const [active, setActive] = useState(expanded);

  useEffect(() => {
    setActive(expanded);
  }, [expanded]);

  return (
    <AccordionSection
      active={active}
      onClick={() => setActive(!active)}
      headerSlot={
        <AccordionListItemBaseNewStyle isActive={active} hasArrow={true}>
          <AccordionTitle bold="bold" size={'lg'}>
            {title}
          </AccordionTitle>
        </AccordionListItemBaseNewStyle>
      }
      contentSlot={content}
    />
  );
};

export default Accordion;
