import { GRID_SLOTS } from '@components/organisms/Container/Container';
import media from '@constants/responsive';
import styled from 'styled-components';

const STORE_INFO_GRID_SLOT = 2;

export const ContainerMenuWrapper = styled.div`
  display: none;
  ${media.desktop} {
    display: block;
    grid-column: span ${STORE_INFO_GRID_SLOT};
  }
`;

export const ChildrenWrapper = styled.div`
  grid-column: span ${GRID_SLOTS};

  ${media.desktop} {
    grid-column: span 12;
  }
`;
