import media from '@constants/responsive';
import { GRID_SLOTS } from 'components/organisms/Container/Container';
import { rem } from 'polished';
import styled from 'styled-components';

export const PageContentWrapper = styled.div`
  margin: 0;
  padding: ${rem(16)} 0 0 0;
  ${media.tabletPortrait} {
    padding: ${rem(24)} 0 0 0;
  }
`;

export const PageWrapper = styled.div`
  grid-column: span ${GRID_SLOTS};
  flex: 1;
`;

export const SideNavWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  max-width: ${rem(249)};
`;
