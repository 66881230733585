import { StoreInfoComponentDataOpeningHoursType } from './types';

export const HIDDEN_STORE_FEATURES_IDS = [
  100, // Burek
  59, // Picadeli
  49, // Färskt bröd
  101, // Grill
].map((id) => {
  return `StoreFeatureId-${id.toString().padStart(10, '0')}`;
});

export const rewriteStoreFeatures = (storeFeatures: { [key: string]: string }) =>
  Object.keys(storeFeatures).reduce((obj, id) => {
    if (!HIDDEN_STORE_FEATURES_IDS.includes(id) && storeFeatures[id]) {
      return {
        ...obj,
        [id]: storeFeatures[id],
      };
    }
    return obj;
  }, {});

// formats days, eg: Mån-Fre
const supportedCopyKeys = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
export const getName = (t: (key: string) => string, name1: string = '', name2: string = '') => {
  if (supportedCopyKeys.includes(name1) && supportedCopyKeys.includes(name2)) {
    return `${t(name1)}-${t(name2)}`;
  }
  if (supportedCopyKeys.includes(name1)) {
    return t(name1);
  }
  return name1;
};
// formats opening hours, eg: 9-19
export const getOpeningHours = (openingHours?: string, closingHours?: string) => {
  if (openingHours === '0' && closingHours === '0') {
    return 'Stängt';
  }
  return !!openingHours && !!closingHours ? `${openingHours}-${closingHours}` : undefined;
};

// opening days
export const getOpeningDays = (t: (key: string) => string, mergedOpeningDays: StoreInfoComponentDataOpeningHoursType) =>
  Object.values(mergedOpeningDays).map(({ name2, name1, closingHours, openingHours }) => ({
    name: getName(t, name1, name2),
    openToClosingHours: getOpeningHours(openingHours, closingHours),
  }));
