import { TabButton } from '@components/atoms/Tab/Tab.styles';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  padding: ${rem(30)} ${rem(12)};

  margin-top: ${rem(9)};
  background-color: ${({ theme }) => theme.colors.colorWhite};

  ${media.tabletPortrait} {
    padding: ${rem(60)} ${rem(60)};
  }

  ${media.desktop} {
    padding: ${rem(60)} ${rem(120)};
  }
`;

export const RecipeDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    width: ${rem(1000)};
  }
`;

export const IconWithTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${rem(9)};
  margin-right: ${rem(30)};
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PictureBox = styled.div`
  height: ${rem(190)};
  margin-bottom: ${rem(12)};
  overflow: hidden;

  ${media.tabletPortrait} {
    height: ${rem(347)};
  }

  ${media.desktop} {
    height: ${rem(560)};
  }
`;

export const RecipeInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(12)} 0;
  ${media.desktop} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const PortionsSectionWrapper = styled.div`
  ${media.desktop} {
    flex: 0 0 50%;
    padding-left: ${rem(32)};
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
`;

export const TabsContainer = styled.div`
  ${TabButton} {
    margin: 0;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: ${rem(32)};
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(8)};
  width: 100%;
`;

export const VerticalBar = styled.div`
  border-left: ${rem(2)} solid ${({ theme }) => theme.colors.colorPampas};
`;

export const StyleOnPicture = styled.div`
  position: absolute;
  bottom: ${rem(52)};
  right: ${rem(40)};
`;

export const PictureSection = styled.div`
  position: relative;
`;
