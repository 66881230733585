import Autocomplete from '@components/molecules/Autocomplete';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledAutocomplete = styled(Autocomplete)`
  left: ${rem(2)};
  top: ${rem(30)};
  ${media.tabletPortrait} {
    max-width: ${rem(337)};
  }
`;
export const StreetWrapper = styled.div`
  position: relative;
`;
