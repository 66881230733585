import { StorePageInfo } from '../useStoreInfoCmsData';

interface StructuredLocalBusiness {
  '@context': 'https://schema.org';
  '@type': 'LocalBusiness';
  name: string;
  address: { [key: string]: string };
  priceRange?: string;
  telephone?: string;
  url?: string;
  image?: string;
}
export const hemkopLogoType = 'https://hemkop.se/images/hemkop_logo_red.svg';
export const hemkopUrl = 'https://www.hemkop.se/';
export const convertLocalBusiness = (storePageInfo: StorePageInfo): StructuredLocalBusiness => {
  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: storePageInfo.storeName || '',

    address: {
      '@type': 'PostalAddress',
      streetAddress: storePageInfo.streetAddress,
      postalCode: storePageInfo.postalCode,
      addressLocality: storePageInfo.town,
    },
    priceRange: '0-0',
    image: hemkopLogoType,
    telephone: storePageInfo.phoneNumber,
    url: hemkopUrl,
  };
};
