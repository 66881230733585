import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import CmsContentSlot from '@components/atoms/CmsContentSlot/CmsContentSlot';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import React from 'react';

interface Props {
  cmsPage: PageWithSlots;
  children?: React.ReactNode;
  slotList: string[];
}

const ShoppingTemplate = ({ cmsPage, children, slotList }: Props) => {
  const { Position1 } = collectSlots(cmsPage.slots, slotList);
  const postion1List = Position1?.component || [];

  return (
    <>
      {children && children}
      <CmsContentSlot
        slotData={Position1}
        id="Content-position1-hemkop-Shoppingpage"
        data-testid="Content-position1-hemkop-Shoppingpage"
      >
        {postion1List.map((component: CmsComponent, index) => (
          <CmsComponent key={component.uuid} component={component} slotName="Position1" slotPosition={index} />
        ))}
      </CmsContentSlot>
    </>
  );
};

export default ShoppingTemplate;
