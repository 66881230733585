import { postalCodeDeliverablity, validateAddress } from '@api';
import { AddressData } from '@api/generated/storefront';
import { checkDeliverabilityWithGeposit } from '@components/molecules/DeliveryHomeSelector/utils';
import { formatAddress } from '@helpers/address';

export interface AddressValues {
  street: string;
  postalCode: string;
  town?: string;
}

export const validateDeliveryAddress = async (
  { street, postalCode, town }: AddressValues,
  onError: (errors: Array<string>) => void,
  onSuccess?: () => void
) => {
  const res = await validateAddress(street, postalCode, town);
  if (res.data) {
    const { response } = res.data;
    if (response?.is_valid) {
      onSuccess && onSuccess();
      return Promise.resolve(response.status_text);
    }
    if (!response?.is_valid) {
      const errors = response.errors && Object.values(response.errors);
      errors && onError(errors);
      return Promise.reject(new Error(response.status_text));
    }
  }
  return Promise.reject(new Error('failed to validate delivery address'));
};

export const validatePostalCode = async (addressForm: AddressValues, onError?: () => void) => {
  try {
    const { postalCode, street, town } = addressForm;
    const res = await postalCodeDeliverablity(addressForm.postalCode);
    if (res.data) {
      const { deliverable, isDivided } = res.data;

      if (deliverable && isDivided) {
        const fullAddress = formatAddress(street, postalCode, town);
        return await checkDeliverabilityWithGeposit(fullAddress);
      }
      return Promise.resolve(deliverable);
    }
  } catch (e) {
    onError && onError();
    return Promise.reject();
  }
  return Promise.reject(new Error('failed to validate postalCode'));
};

export const concatGepositAddress = (
  { street = '', street_number: streetNumber = '', postalcode = '', letter = '', locality = '' }: GepositAddress,
  fullAddress = false
): string => {
  const array = [];
  if (street) {
    array.push(street);
  }

  const streetNumberAndLetter = `${streetNumber}${letter}`;
  if (streetNumberAndLetter) {
    array.push(streetNumberAndLetter);
  }

  if (fullAddress && postalcode) {
    array.push(postalcode);
  }
  if (fullAddress && locality) {
    array.push(locality);
  }
  return array.join(' ');
};

export const mergeAddressAndGepositAddress = (address: AddressData, gepositAddress: GepositAddress): AddressData => {
  return {
    longitude: address.longitude,
    latitude: address.latitude,
    firstName: address.firstName,
    lastName: address.lastName,
    email: address.email,
    cellphone: address.cellphone,
    companyName: address.companyName,
    postalCode: gepositAddress.postalcode || address.postalCode,
    line1: concatGepositAddress(gepositAddress, false) || address.line1,
    line2: address.line2,
    town: gepositAddress.locality || address.town,
  };
};
