import Heading from '@components/atoms/Heading';
import Link from '@components/atoms/Link';
import Paragraph from '@components/atoms/Paragraph';
import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import useTranslation from 'next-translate/useTranslation';
import { MapImage, MapInfoWindowStack, MapWrapper } from './MapComponent.styles';
import { useMapComponent } from './useMapComponent';

interface MapProps {
  storeData: StoreInfoCmsData;
}
const MapComponent = ({ storeData }: MapProps) => {
  const { t } = useTranslation('store');
  const { storeAddress, storePostalCodeAndTown, mapImageUrl, googleMapLink } = useMapComponent(storeData);

  const haveNoStoreGeoPoints = !storeData.latitude || !storeData.longitude;

  if (haveNoStoreGeoPoints) {
    return null;
  }

  return (
    <MapWrapper>
      <MapImage src={mapImageUrl} alt={t('mapComponent->mapsImageAltText')} />
      <MapInfoWindowStack $space="xsmall">
        <div>
          <Heading type="h3" size="small">
            {storeAddress}
          </Heading>
          <Paragraph>{storePostalCodeAndTown}</Paragraph>
        </div>
        <Link href={googleMapLink} target="_blank">
          {t('mapComponent->findYourWay')}
        </Link>
      </MapInfoWindowStack>
    </MapWrapper>
  );
};

export default MapComponent;
