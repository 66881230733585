import { StoreInfoText } from '../StoreInfo.styles';

interface Props {
  storeFeatures: string[];
}

const StoreFeatures = ({ storeFeatures }: Props) => {
  return (
    <div>
      {storeFeatures.map((feature) => (
        <StoreInfoText key={feature}>{feature}</StoreInfoText>
      ))}
    </div>
  );
};

export default StoreFeatures;
