import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';
import { RIGHT_MENU_WIDTH } from './StoreSideMenu/StoreSideMenu.styles';

const GAP = 10;
export const StorePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  ${media.desktop} {
    flex-direction: row;
    gap: ${rem(GAP)};
  }
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  ${media.tabletPortrait} {
    gap: ${rem(16)};
  }
  ${media.desktop} {
    width: calc(100% - ${rem(RIGHT_MENU_WIDTH - GAP)});
  }
`;
