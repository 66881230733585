import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import { default as OldstorePage } from '@features/__DEPRECATED__/StorePage';
import { default as NewStorePage } from '@features/storepage/StorePage';
import useFeature from '@hooks/useFeature';

interface Props {
  cmsPage: PageWithSlots;
  slotList: string[];
}

const StoreTemplate = ({ cmsPage, slotList }: Props) => {
  const { MainContent2, RightContent } = collectSlots(cmsPage.slots, slotList);
  const mainContentComponents = MainContent2?.component || [];
  const rightContentComponents = RightContent?.component || [];

  const newStorePageEnabled = useFeature('FEATURE_NEW_STORE_PAGE_MR');

  return (
    <>
      {!newStorePageEnabled ? (
        <OldstorePage rightContentComponents={rightContentComponents}>
          {mainContentComponents.map((component: CmsComponent, index) => (
            <CmsComponent key={component.uuid} component={component} slotName="MainContent2" slotPosition={index} />
          ))}
        </OldstorePage>
      ) : (
        <NewStorePage rightContentComponents={rightContentComponents}>
          {mainContentComponents.map((component: CmsComponent, index) => (
            <CmsComponent key={component.uuid} component={component} slotName="MainContent2" slotPosition={index} />
          ))}
        </NewStorePage>
      )}
    </>
  );
};

export default StoreTemplate;
