import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import CmsContentSlot from '@components/atoms/CmsContentSlot/CmsContentSlot';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import DeliveryBanner from '@components/molecules/DeliveryBanner/DeliveryBanner';
import { rem } from 'polished';
import styled from 'styled-components';

const HomePageContainer = styled.div`
  & > * {
    margin-bottom: ${rem(10)};
  }
`;
interface Props {
  cmsPage: PageWithSlots;
  slotList: string[];
}

const HomePageTemplate = ({ cmsPage, slotList }: Props) => {
  const { Position1, Position2 } = collectSlots(cmsPage.slots, slotList);
  const postion1List = Position1?.component || [];
  const postion2List = Position2?.component || [];

  return (
    <HomePageContainer>
      <DeliveryBanner />
      <CmsContentSlot
        slotData={Position2}
        id="Content-position2-hemkop-Homepage"
        data-testid="Content-position2-hemkop-Homepage"
      >
        {postion2List.map((component: CmsComponent, index) => (
          <CmsComponent key={component.uuid} component={component} slotName="Position2" slotPosition={index} />
        ))}
      </CmsContentSlot>
      <CmsContentSlot
        slotData={Position2}
        id="Content-position1-hemkop-Homepage"
        data-testid="Content-position1-hemkop-Homepage"
      >
        {postion1List.map((component: CmsComponent, index) => (
          <CmsComponent key={component.uuid} component={component} slotName="Position1" slotPosition={index} />
        ))}
      </CmsContentSlot>
    </HomePageContainer>
  );
};

export default HomePageTemplate;
