import axios, { CancelToken } from 'axios';

interface Args {
  code: string;
  b2b: boolean;
}
const getDeliverabilityStatus = (data: Args, cancelToken: CancelToken) => {
  const { code, b2b } = data;
  return axios.get(`/axfood/rest/shipping/delivery/${code}/deliverability`, {
    cancelToken,
    params: {
      code,
      b2b,
    },
  });
};
export default getDeliverabilityStatus;
