const SchemaScript = ({ structuredData }: { structuredData: object }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredData),
      }}
    />
  );
};

export default SchemaScript;
