import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';
import { LinkContent } from './SideNavLink/SideNavLink.styles';

export const MenuWrapper = styled.nav<{ $shouldOpenSideNav: boolean }>`
  min-width: ${rem(282)};
  height: 100%;
  position: fixed;
  top: 0;
  flex-shrink: 0;
  left: ${({ $shouldOpenSideNav }) => ($shouldOpenSideNav ? 0 : rem(-300))};
  z-index: 100;
  transition: left 0.3s ease-in-out;
  background: ${({ theme }) => theme.colors.colorDesertStorm};
  overflow-y: scroll;

  ${media.desktop} {
    min-width: 0;
    grid-column: span 2 / span 2;
    height: 90vh;
    scrollbar-width: thin;
    position: sticky;
    left: 0;
    top: ${rem(75)};
    background-color: transparent;
    z-index: 0;
    overflow-x: hidden;
  }
`;

export const Menu = styled.menu`
  list-style-type: none;
  ${media.desktop} {
    margin-top: 0;
  }
`;

export const MenuHeaderWrapper = styled.div<{ $shouldOpenSideNav: boolean }>`
  top: ${({ $shouldOpenSideNav }) => ($shouldOpenSideNav ? rem(0) : rem(64))};
  left: ${({ $shouldOpenSideNav }) => ($shouldOpenSideNav ? 0 : '-300px')};
  position: ${({ $shouldOpenSideNav }) => ($shouldOpenSideNav ? 'sticky' : 'relative')};
  z-index: 101;
  background-color: ${({ $shouldOpenSideNav, theme }) =>
    $shouldOpenSideNav ? theme.colors.colorDesertStorm : 'transparent'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  padding: 0 ${rem(16)} 0 0;
  transition: left 0.3s ease-in-out;
  ${media.desktop} {
    left: 0;
    position: relative;
    background-color: transparent;
  }
`;

export const MenuheaderWrapper = styled.div``;

export const ButtonWrapper = styled.div``;

export const TextContainer = styled(LinkContent)<{ $opacity: boolean }>`
  opacity: ${({ $opacity }) => $opacity && 0.7};
  && {
    height: ${rem(48)};
  }
`;

export const Divider = styled.hr`
  margin: ${rem(24)} ${rem(16)};
  height: ${rem(1)};
  background: ${({ theme }) => theme.colors.colorSilver};
  border: 0;
`;
