import Icon from '@components/atoms/Icon';
import IconArrowDown from '@public/icons/regularIcons/icon-arrow-down.svg';
import { ReactNode } from 'react';
import { Content, Details, Summary } from './Accordion.styles';
interface Props {
  summary: ReactNode;
  children: ReactNode;
  open?: boolean;
  name?: string;
}
const Accordion = ({ summary, children, open = true, name }: Props) => {
  return (
    <Details data-testid="details" open={open} name={name}>
      <Summary>
        {summary}
        <Icon icon={IconArrowDown} />
      </Summary>
      <Content>{children}</Content>
    </Details>
  );
};

export default Accordion;
