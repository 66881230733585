import { getCategoryNavigationTree } from '@api';
import { URLPREFIXES } from '@constants/links';
import { SideNavNode } from '@features/sideNav/useSideNavData/useSideNavData';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import { selectAssortmentStoreId } from '@selectors/delivery';
import useSWR from 'swr';

const mapCategoryNodes = (nodes: any[], parents: string[], trackingPath: string): SideNavNode[] => {
  return nodes.map((node) => mapCategoryNode(node, parents, trackingPath));
};

// eslint-disable-next-line fp/no-rest-parameters
const mapId = (...levels: string[]) => {
  return levels.map((s) => s.trim()).join(' | ');
};

const mapCategoryNode = (node: any, parentIds: string[], trackingPath: string): SideNavNode => {
  const { id: categoryId, title, url: urlPath } = node;
  const url = URLPREFIXES.CATEGORIES + urlPath;
  const trackingId = trackingPath ? mapId(trackingPath, title) : title;

  const id = mapId(categoryId, title);

  return {
    id,
    title,
    url,
    trackingId,
    parentIds,
    childNodes: mapCategoryNodes(node.children, [...parentIds, id], trackingId),
  };
};

const useAssortmentLinks = () => {
  const assortmentStoreId = useAppSelector(selectAssortmentStoreId);
  const { customerFetchIsRequired } = useCustomer();

  const res = useSWR<any>(customerFetchIsRequired ? { fn: 'getCategoryNavigationTree', assortmentStoreId } : null, () =>
    getCategoryNavigationTree(assortmentStoreId)
  );

  return res?.data && res?.data.children ? mapCategoryNodes(res?.data?.children, [], '') : [];
};

export default useAssortmentLinks;
