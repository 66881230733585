import { getMostLikelyAddress } from '@api/CartApi';
import { AddressData } from '@api/generated/storefront';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import { selectCartDeliveryAddress, selectHasFetchedCart } from '@selectors/cart';
import useSWR, { SWRConfiguration } from 'swr';

const useMostLikelyAddress = () => {
  const { customerId, hasFetchedCustomer } = useCustomer();
  const cartDeliveryAddress = useAppSelector(selectCartDeliveryAddress);
  const hasFetchedCart = useAppSelector(selectHasFetchedCart);

  const options: SWRConfiguration = {
    // comment out the line below to see how address section
    // flashes when updating delivery info (doorCode) in checkout
    keepPreviousData: true,
  };

  // cartDeliveryAddress has a unique id that is updated every time address is set on cart in backend
  // to get a cachable value for swr, we exclude that value in the cache key
  const { id, ...cartAddressWithoutId } = cartDeliveryAddress || {};

  const cacheKey =
    hasFetchedCustomer && hasFetchedCart
      ? { path: 'customer-likely-delivery-address', customerId, cartDeliveryAddress: cartAddressWithoutId }
      : null;
  return useSWR<AddressData>(cacheKey, getMostLikelyAddress, options);
};

export default useMostLikelyAddress;
