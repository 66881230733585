import Container from '@components/organisms/Container';
import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import { ReactNode } from 'react';
import StoreInfoDesktop from '../StoreInfo/StoreInfoDesktop';
import { ChildrenWrapper, ContainerMenuWrapper } from './WithStoreInfo.styles';

interface Props {
  children: ReactNode;
  shouldRender?: boolean;
  storeInfo: StoreInfoCmsData;
}

const WithStoreInfoMenu = ({ children, shouldRender = true, storeInfo }: Props) => {
  if (!shouldRender) return <>{children}</>;
  return (
    <Container>
      <ContainerMenuWrapper>
        <StoreInfoDesktop storeInfo={storeInfo} />
      </ContainerMenuWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Container>
  );
};

export default WithStoreInfoMenu;
