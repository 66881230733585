import axios, { CancelToken } from 'axios';

interface Args {
  address: string;
  rows: string;
}

const getFullAddressSuggestions = (data: Args, cancelToken: CancelToken) => {
  const { address, rows } = data;
  return axios.get(`/axfood/rest/suggest/addressline?addressline=${address}&maxRows=${rows}`, { cancelToken });
};
export default getFullAddressSuggestions;
