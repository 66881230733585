import Heading from '@components/atoms/Heading';
import Paragraph from '@components/atoms/Paragraph';
import { FlyerBannerContent } from '@features/weeklyFlyer';
import useTranslation from 'next-translate/useTranslation';
import { Container, Content, Vector } from './StoreWeeklyFlyer.styles';

interface StoreWeeklyFlyerProps {
  flyerLink: string;
}

const StoreWeeklyFlyer = ({ flyerLink }: StoreWeeklyFlyerProps) => {
  const { t } = useTranslation('store');

  return (
    <Container>
      <Vector />
      <Content>
        <FlyerBannerContent
          flyerLink={flyerLink}
          header={<Heading type="h2">{t('weeklyFlyer->header')}</Heading>}
          body={<Paragraph size="medium">{t('weeklyFlyer->body')}</Paragraph>}
        />
      </Content>
    </Container>
  );
};

export default StoreWeeklyFlyer;
