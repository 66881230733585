import Icon from '@components/atoms/Icon/Icon';
import SchemaScript from '@components/atoms/SchemaScript';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph/Paragraph';
import ContactContent from '../StoreSideMenu/ContactContent';
import LocationContent from '../StoreSideMenu/LocationContent';
import OpeningHoursContent from '../StoreSideMenu/OpeningHoursContent';
import ServiceContent from '../StoreSideMenu/ServiceContent';
import { convertLocalBusiness } from '../StoreSideMenu/formatLocalBusinessStructuredData';
import useResponsive from '@hooks/useResponsive';
import IconTag from '@public/icons/regularIcons/icon-tag.svg';
import useTranslation from 'next-translate/useTranslation';
import { StorePageInfo } from '../useStoreInfoCmsData';
import Accordion from './Accordion';
import { AccordionWrapper, FlyerWrapper, RightMenuWrapper, RoundIcon } from './StoreSideMenu.styles';
interface Props {
  storeInfo: StorePageInfo;
}

const StoreSideMenu = ({ storeInfo }: Props) => {
  const { isDesktopOrGreater } = useResponsive();
  const { flyerUrl, openingDays, storeFeatures } = storeInfo;
  const { t } = useTranslation('oldstore');

  const localBusinessStructuredData = convertLocalBusiness(storeInfo);

  return (
    <RightMenuWrapper>
      <SchemaScript structuredData={localBusinessStructuredData} />
      {flyerUrl && (
        <FlyerWrapper>
          <RoundIcon>
            <Icon size={32} icon={IconTag} color="white" />
          </RoundIcon>
          <div>
            <Paragraph bold="bold">{t('store->offers')}</Paragraph>
            <Link margin={false} href={flyerUrl} target="_blank">
              <Paragraph size="md">{t('store->weeklyOffers')}</Paragraph>
            </Link>
          </div>
        </FlyerWrapper>
      )}

      {openingDays && (
        <AccordionWrapper>
          <Accordion
            title={t('store->openingHours')}
            content={<OpeningHoursContent storeInfo={storeInfo} />}
            expanded={isDesktopOrGreater}
          />
        </AccordionWrapper>
      )}
      {storeFeatures?.length > 0 && (
        <AccordionWrapper>
          <Accordion
            title={t('store->service')}
            content={<ServiceContent storeInfo={storeInfo} />}
            expanded={isDesktopOrGreater}
          />
        </AccordionWrapper>
      )}
      <AccordionWrapper>
        <Accordion
          title={t('store->location->title')}
          content={<LocationContent storeInfo={storeInfo} />}
          expanded={isDesktopOrGreater}
        />
      </AccordionWrapper>
      <AccordionWrapper>
        <Accordion
          title={t('store->contact->title')}
          content={<ContactContent storeInfo={storeInfo} />}
          expanded={isDesktopOrGreater}
        />
      </AccordionWrapper>
    </RightMenuWrapper>
  );
};
export default StoreSideMenu;
