import { PageWithSlots } from '@api';
import ArticleTemplate from '@components/templates/cmsPageTemplates/ArticleTemplate';
import CategoryTemplate from '@components/templates/cmsPageTemplates/CategoryTemplate';
import DynamicMasterTemplate from '@components/templates/cmsPageTemplates/DynamicMasterTemplate';
import HomePageTemplate from '@components/templates/cmsPageTemplates/HomePageTemplate';
import ShoppingTemplate from '@components/templates/cmsPageTemplates/ShoppingTemplate';
import StoreTemplate from '@components/templates/cmsPageTemplates/StoreTemplate';
import { slotsLiteral } from './constants';
import { ReactNode } from 'react';

const getSlotList = (template = '') => {
  return slotsLiteral[template as keyof typeof slotsLiteral] || [];
};

const getCmsTemplate = (cmsPage?: PageWithSlots, children?: ReactNode) => {
  if (!cmsPage) return null;

  const slotList = getSlotList(cmsPage.template);

  const templateLiteral = {
    HemkopHomepageTemplate: <HomePageTemplate cmsPage={cmsPage} slotList={slotList} />, // Handla-sidan.
    HemkopShoppingTemplate: (
      <ShoppingTemplate cmsPage={cmsPage} slotList={slotList}>
        {children}
      </ShoppingTemplate>
    ), // Varukorgssidan + Kategori-sidan (t.ex. "sortiment/kott-och-kyckling").
    HemkopDynamicMasterTemplate: (
      <DynamicMasterTemplate cmsPage={cmsPage} slotList={slotList}>
        {children}
      </DynamicMasterTemplate>
    ), // Start-sidan och övriga sidor.
    HemkopArticleTemplate: <ArticleTemplate cmsPage={cmsPage} slotList={slotList} />, // article pages (eg. "artikel/anvandarvillkor")
    HemkopStoreTemplate: <StoreTemplate cmsPage={cmsPage} slotList={slotList} />,
    hemkopCategoryTemplate: (
      <CategoryTemplate cmsPage={cmsPage} slotList={slotList}>
        {children}
      </CategoryTemplate>
    ),
  };

  return templateLiteral[cmsPage.template as keyof typeof templateLiteral] || null;
};

export default getCmsTemplate;
