import Paragraph from '@components/atoms/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const background = '/images/icon-paragraph-message-background.svg';

export const ParagraphMessagecontainer = styled.div`
  background-image: url(${background});
  background-color: ${({ theme }) => theme.colors.colorWhite};
  background-repeat: no-repeat;
  background-position: 0 ${rem(-30)};
  background-size: cover;
  padding: ${rem(16)};
  height: ${rem(490)};

  ${media.tabletPortrait} {
    padding: ${rem(40)};
    background-position: 0 ${rem(-80)};
  }
  ${media.desktop} {
    background-position: 0 ${rem(-30)};
  }
  ${media.desktopXL} {
    background-position: 0 ${rem(-100)};
  }
  ${media.desktopXXL} {
    background-position: 0 ${rem(-220)};
  }
`;

export const StyledParagraphText = styled(Paragraph)`
  margin-top: ${rem(24)};
`;
