import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: ${rem(12)};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: ${rem(433)};

  ${media.largeMobile} {
    border-radius: ${rem(24)};

    justify-content: flex-start;
    height: ${rem(400)};
  }
`;

export const PaddedContainer = styled.div`
  position: absolute;
  width: 100%;
  padding: ${rem(8)};

  ${media.largeMobile} {
    padding: ${rem(16)};
  }
`;

const panelPadding = 24;

export const panelContentMaxWidth = 492;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  overflow: hidden;
  padding: ${rem(panelPadding)} 0;
  border-radius: ${rem(12)};

  ${media.largeMobile} {
    width: ${rem(2 * panelPadding + panelContentMaxWidth)};
    align-items: flex-start;

    padding: ${rem(panelPadding)};
    border-radius: ${rem(24)};
  }
`;
