import { Stack } from '@components/atoms/Stack';
import { StoreLocation } from '@features/storepage/types';
import IconDelivery from '@public/icons/regularIcons/icon-delivery.svg';
import IconPin from '@public/icons/regularIcons/icon-pin.svg';
import useTranslation from 'next-translate/useTranslation';
import LinkWithIcon from '../LinkWithIcon';
import { StoreInfoText } from '../StoreInfo.styles';
interface Props {
  location: StoreLocation;
}
const Location = ({ location }: Props) => {
  const { t } = useTranslation('store');
  const { streetAddress, postalAddress, mapUrl, directionsUrl } = location;

  return (
    <Stack $space="nano">
      <div>
        <StoreInfoText>{streetAddress}</StoreInfoText>
        <StoreInfoText>{postalAddress}</StoreInfoText>
      </div>
      <div>
        {mapUrl && <LinkWithIcon href={mapUrl} icon={IconPin} text={t('info->onMap')} />}
        {directionsUrl && <LinkWithIcon href={directionsUrl} icon={IconDelivery} text={t('info->directions')} />}
      </div>
    </Stack>
  );
};

export default Location;
