import styled from 'styled-components';
import { rem } from 'polished';
import { HorizontalAlignmentType, VerticalAlignmentType, FlexDirection } from './ErrorMessage';

interface Props {
  $horizontalAlignment?: HorizontalAlignmentType;
  $verticalAlignment?: VerticalAlignmentType;
  $flexDirection: FlexDirection;
}

export const ErrorMessageWrapper = styled.div<Props>`
  font-size: ${rem('16px')};
  max-width: ${rem('600px')};
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.$flexDirection};
  color: ${({ theme }) => theme.colors.colorPrimary};
  padding: ${rem('8px')} 0;
  justify-content: ${(props) =>
    (props.$horizontalAlignment === 'left' && 'flex-start') ||
    (props.$horizontalAlignment === 'center' && 'center') ||
    (props.$horizontalAlignment === 'right' && 'flex-end')};
  align-items: ${(props) =>
    (props.$verticalAlignment === 'top' && 'flex-start') ||
    (props.$verticalAlignment === 'center' && 'center') ||
    (props.$verticalAlignment === 'bottom' && 'flex-end')};
`;
