import Heading from '@components/atoms/Heading';
import { ContentContainer, OpenHours, Welcome } from '@features/storepage/components/StoreHero/StoreHero.styles';
import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import useTranslation from 'next-translate/useTranslation';
import { heroImageUrl } from './constants';
import StoreHeroContainer from './StoreHeroContainer';

interface Props {
  storeName: StoreInfoCmsData['storeName'];
  openStatusKey: StoreInfoCmsData['openStatusKey'];
  openingHours: StoreInfoCmsData['openingHours'];
}

const StoreHero = ({ storeName, openStatusKey, openingHours }: Props) => {
  const { t } = useTranslation('store');

  return (
    <StoreHeroContainer imageUrl={heroImageUrl} imageAltText={t('hero->imageAltText')}>
      <ContentContainer>
        <Welcome>{t('hero->welcome')}</Welcome>
        <Heading>{storeName}</Heading>
        <OpenHours>
          {openStatusKey && <span>{t(`hero->openingHours->${openStatusKey}`)}</span>}
          {openingHours && <span>{openingHours}</span>}
        </OpenHours>
      </ContentContainer>
    </StoreHeroContainer>
  );
};

export default StoreHero;
