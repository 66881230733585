import Image from 'next/image';
import { ReactNode } from 'react';
import { Container, PaddedContainer, Panel } from './StoreHeroContainer.styles';

const StoreHeroContainer = ({
  imageUrl,
  imageAltText,
  children,
}: {
  imageUrl: string;
  imageAltText: string;
  children: ReactNode;
}) => (
  <Container>
    <Image
      src={imageUrl}
      alt={imageAltText || 'Hero image'}
      style={{ objectFit: 'cover', objectPosition: 'top' }}
      fill
    />
    <PaddedContainer>
      <Panel>{children}</Panel>
    </PaddedContainer>
  </Container>
);

export default StoreHeroContainer;
