export type MergedOpeningHoursNameType =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export type OpeningHours = {
  storeOpen?: string;
  name1: MergedOpeningHoursNameType;
  name2?: MergedOpeningHoursNameType;
  openingHours: string;
  closingHours: string;
};

export type StoreInfoComponentDataOpeningHoursType = {
  [key: string]: OpeningHours;
};

export type OpeningHoursMessageKey = 'store.openMessage.soon' | 'store.openMessage.open' | 'store.openMessage.notOpen';

export type StoreFeatures = {
  [key: string]: string;
};

export type Features = {
  features: string[];
  visible: boolean;
};

export type OpenAndClosingHours = Array<{ name?: string; openToClosingHours?: string }>;

export type StoreLocation = {
  streetAddress: string;
  postalAddress: string;
  mapUrl?: string;
  directionsUrl?: string;
  visible: boolean;
};

export type StoreContactInfo = { email?: string; phoneNumber?: string; visible: boolean };

export type StoreOpeningHours = {
  openingDays?: OpenAndClosingHours;
  storeFeatureOpeningDays?: Array<{ storeFeature?: string; openingHours: OpenAndClosingHours }>;
  specialOpeningDays?: OpenAndClosingHours;
  visible: boolean;
};

export type StoreInfoComponentDataType = {
  container: string;
  flyerUrl: string;
  hideInDesktop: string;
  hideInMobile: string;
  isOpen: string;
  mergedOpeningDays: StoreInfoComponentDataOpeningHoursType;
  specialOpeningDays: StoreInfoComponentDataOpeningHoursType;
  modifiedTime: string;
  name: string;
  openingHoursMessageKey: OpeningHoursMessageKey;
  openingStoreMessageValue: string;
  showComponent: string;
  showContactInfo: string;
  showFeatureOpeningDays: string;
  showFlyer: string;
  showOpeningHours: string;
  showStoreFeatures: string;
  mergedFeatureOpeningDaysForWeek: { [key: string]: StoreInfoComponentDataOpeningHoursType };
  store?: Partial<{
    // note: this seem to be totally different from a StoreType
    displayName: string;
    flyerURL: string;
    geoPoint: {
      latitude: string;
      longitude: string;
    };
    address?: Partial<{
      line1: string;
      postalCode: string;
      town: string;
      phone: string;
      email: string;
    }>;
  }>;
  storeEmail: unknown;
  storeFax: unknown;
  storeFeatures: StoreFeatures;
  storePhone: string;
  storePostalCode: string;
  storeStreetName: string;
  storeTown: string;
  synchronizationBlocked: string;
  typeCode: string;
  uid: string;
  uuid: string;
  weekdayOpeningHoursForWeek: {
    [key: string]: {
      storeOpen: string;
      name1: string;
      openingHours: string;
      closingHours: string;
    };
  };
};

export const componentIsStoreInfoComponentDataType = (
  cmsComponent: unknown
): cmsComponent is StoreInfoComponentDataType =>
  cmsComponent !== null &&
  typeof cmsComponent === 'object' &&
  'typeCode' in cmsComponent &&
  cmsComponent?.typeCode === 'StoreInfoComponent';
