import { PageWithSlots } from '@api';
import getCmsTemplate from '@helpers/cms/getCmsTemplate';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  cmsPage?: PageWithSlots;
}

const CmsTemplate = ({ cmsPage, children }: Props) => {
  return getCmsTemplate(cmsPage, children);
};

export default CmsTemplate;
