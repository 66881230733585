import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import useResponsive from '@hooks/useResponsive';
import config from 'config';

export const useMapComponent = (store: StoreInfoCmsData) => {
  const storeAddress = store?.streetAddress || '';
  const storePostalCodeAndTown = `${store?.postalCode}, ${store?.town}`;
  const zoom = 13;
  const googleMapAPIKey = config?.GOOGLE_MAPS_API_KEY;
  const size = `800x540`;
  const { isTabletPortraitOrGreater } = useResponsive();

  const adjustLatitudeForOffCenter = (latitude: number) => {
    const offsetMeters = isTabletPortraitOrGreater ? -250 : 250;
    const metersPerDegreeLat = 111320;
    const offsetDegrees = offsetMeters / metersPerDegreeLat;
    const offCenterlatitude = latitude - offsetDegrees;

    return offCenterlatitude;
  };

  const offCenterlatitude = adjustLatitudeForOffCenter(Number(store.latitude));

  const latitude = store?.latitude;
  const longitude = store?.longitude;

  const googleMapLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  const markerIcon = `icon:https://www.hemkop.se/images/icon-pin-red.png|${latitude},${longitude}`;
  const googleMapApi = 'https://maps.googleapis.com/maps/api/staticmap';

  const mapImageUrl = `${googleMapApi}?center=${offCenterlatitude},${longitude}&zoom=${zoom}&size=${size}&markers=${markerIcon}&key=${googleMapAPIKey}`;

  return {
    storeAddress,
    storePostalCodeAndTown,
    googleMapLink,
    mapImageUrl,
  };
};
