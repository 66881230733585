import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import StoreInfo from './StoreInfo';
import { HiddenOnDesktop } from './StoreInfo.styles';

export const StoreInfoMobile = ({ storeInfo }: { storeInfo: StoreInfoCmsData }) => {
  return (
    <HiddenOnDesktop>
      <StoreInfo storeInfo={storeInfo} defaultExpanded={false} />
    </HiddenOnDesktop>
  );
};
