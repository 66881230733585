import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Icon from '@components/atoms/Icon';
import IconAlertCircle from '@public/icons/regularIcons/icon-alert-circle.svg';
import { memo, ReactElement } from 'react';
import { ErrorMessageWrapper } from './ErrorMessage.styles';

export declare type HorizontalAlignmentType = 'left' | 'center' | 'right';
export declare type VerticalAlignmentType = 'top' | 'center' | 'bottom';
export declare type FlexDirection = 'row' | 'column';
interface Props {
  errorMessage: string | ReactElement;
  horizontalAlignment?: HorizontalAlignmentType;
  verticalAlignment?: VerticalAlignmentType;
  flexDirection?: FlexDirection;
}

const ErrorMessage = ({
  errorMessage,
  horizontalAlignment = 'center',
  verticalAlignment = 'center',
  flexDirection = 'row',
}: Props) => {
  return (
    <ErrorMessageWrapper
      $horizontalAlignment={horizontalAlignment}
      $verticalAlignment={verticalAlignment}
      $flexDirection={flexDirection}
      data-testid="container"
      data-cy="error-message"
    >
      <Icon icon={IconAlertCircle} margin="right" color="red" data-testid="icon-error" />
      <Paragraph>{errorMessage}</Paragraph>
    </ErrorMessageWrapper>
  );
};

export default memo(ErrorMessage);
