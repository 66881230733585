import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const RIGHT_MENU_WIDTH = 230;

export const RightMenuWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${rem(2)};
  min-width: ${rem(RIGHT_MENU_WIDTH)};
  margin-bottom: ${rem(10)};
`;

export const RoundIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const FlyerWrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  padding: ${rem(16)} ${rem(10)};
`;

export const OpeningHoursWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Title = styled(Paragraph)`
  margin: ${rem(12)} 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  padding: 0 0 ${rem(10)} 0;
`;

export const GapLessContainer = styled(ContentContainer)`
  gap: 0;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
`;

export const StyledLinkArea = styled(LinkArea)`
  &:hover {
    color: ${({ theme }) => theme.colors.colorTextSecondary};
  }
`;

export const AccordionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  padding: 0 ${rem(10)};
`;
export const AccordionTitle = styled(Paragraph)`
  ${media.desktop} {
    color: ${({ theme }) => theme.colors.colorPrimary};
  }
`;
