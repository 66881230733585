import { rem } from 'polished';
import styled from 'styled-components';
import Button from '@components/atoms/Button';
import { InputLabel, InputStyled } from '@components/atoms/InputField/InputField.styles';
import { StyledAutocomplete } from '@components/organisms/GepositAddress/GepositAddress.styles';
export const desktopSplash = '/images/banner_splash_grey.svg';

export const DeliveryBannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  background-image: url(${desktopSplash});
  background-position: -${rem(144)} center;
  background-repeat: no-repeat;
  background-size: ${rem(858)};
  border-radius: ${rem(16)};
  padding: ${rem(32)} ${rem(48)};
  height: ${rem(140)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeliverytextContent = styled.div`
  max-width: ${rem(432)};
`;

export const DeliveryAddressForm = styled.form`
  height: ${rem(59)};
  width: ${rem(397)};
  padding: ${rem(8)};
  position: relative;
  display: flex;
  flex: auto 0;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.colors.colorCararra};
  background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  border-radius: ${rem(8)};

  ${InputStyled} {
    border: none;
    font-size: ${rem(14)};
  }

  ${InputLabel} {
    top: 6px;
  }

  ${StyledAutocomplete} {
    top: ${rem(64)};
    left: ${rem(-1)};
    width: 100%;
    max-width: ${rem(397)};
    border-radius: ${rem(16)};
  }
`;

export const GoToDeliveryModalButton = styled(Button)`
  padding: ${rem(8)} ${rem(16)} ${rem(8)} ${rem(24)};
  width: 100%;
  max-width: ${rem(100)};
`;
