import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import useAppRouter from '@hooks/useAppRouter';
import useResponsive from '@hooks/useResponsive';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import { Container } from '../../findStore/FindStore.styles';
import { MainContentWrapper, StorePageWrapper } from './StorePage.styles';
import StoreSideMenu from './StoreSideMenu';
import useStoreInfoCmsData from './useStoreInfoCmsData';

interface Props {
  children: ReactNode[];
  rightContentComponents: MultiColumnContainerComponentType[];
}

const StorePage = ({ children, rightContentComponents }: Props) => {
  const { query } = useAppRouter();
  const { t } = useTranslation('oldstore');

  const columnContainerData = rightContentComponents[0].columnContainerData as ColumnContainerData;
  const storeId = (typeof query.id === 'string' && query.id) || '';

  const storeInfo = useStoreInfoCmsData(storeId, columnContainerData.cmsComponents);

  const { storeName, openingHoursMessageKey, openingStoreMessageValue } = storeInfo;
  const { isDesktopOrGreater } = useResponsive();

  return (
    <StorePageWrapper>
      <MainContentWrapper>
        {storeName && (
          <Container>
            <Heading type="h1">{storeName}</Heading>
            {openingHoursMessageKey && (
              <Paragraph bold="bold">
                {t(openingHoursMessageKey)} {openingStoreMessageValue}
              </Paragraph>
            )}
          </Container>
        )}
        {!isDesktopOrGreater && <StoreSideMenu storeInfo={storeInfo} />}
        {children}
      </MainContentWrapper>
      {isDesktopOrGreater && <StoreSideMenu storeInfo={storeInfo} />}
    </StorePageWrapper>
  );
};

export default StorePage;
