import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import StoreInfo from './StoreInfo';
import { HiddenOnMobile } from './StoreInfo.styles';

export const StoreInfoDesktop = ({ storeInfo }: { storeInfo: StoreInfoCmsData }) => {
  return (
    <HiddenOnMobile>
      <StoreInfo storeInfo={storeInfo} defaultExpanded={true} />
    </HiddenOnMobile>
  );
};
export default StoreInfoDesktop;
