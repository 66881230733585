import Autocomplete from '@components/molecules/Autocomplete';
import media from '@constants/responsive';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';

export const AutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${rem(380)};
`;

interface DeliveryHomeSelectorWrapperProps {
  $marginBottom: boolean;
}
export const DeliverHomeSelectorWrapper = styled.div<DeliveryHomeSelectorWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.$marginBottom &&
    css`
      ${media.tabletPortrait} {
        margin-bottom: 0;
      }
      ${media.desktop} {
        margin-bottom: ${rem(100)};
      }
    `}
`;

export const LookUpAddressInstructions = styled.div`
  text-align: center;
  padding: ${rem(20)} 0 ${rem(32)} 0;
  max-width: ${rem(262)};
  ${media.tabletPortrait} {
    padding: ${rem(50)} 0 ${rem(32)} 0;
    max-width: unset;
  }
`;

export const AutocompleteList = styled(Autocomplete)`
  margin: 0;
  list-style: none;
  box-shadow: ${({ theme }) => `0 ${rem(4)} ${rem(5)} ${theme.colors.colorBoxShadow}`};
  max-height: ${rem(200)};
  padding: ${rem(2)} ${rem(4)};
  overflow: auto;
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  margin: ${rem(20)} 0;
`;

export const InfoMessageWrapper = styled.div``;

export const SuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessageStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubMessageWrapper = styled.div`
  margin-bottom: ${rem(20)};
  text-align: center;
`;

export const SubMessageLinkStyles = styled.span`
  cursor: pointer;
  margin: 0 ${rem(3)};
  text-decoration: underline;
`;

export const SlotsSelectorWrapper = styled.div<{ $disabled: boolean }>`
  position: relative;
  text-align: center;
  border-top: ${({ theme }) => `${rem(2)} solid ${theme.colors.colorCararra}`};
  margin: ${rem(30)} 0;
  padding: ${rem(30)} 0;
  max-width: 100%;
  ${({ theme, $disabled }) =>
    $disabled &&
    `
    &::before {
      content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: ${rgba(theme.colors.colorWhite, 0.8)};
    }
  `}
`;

export const SlotSelectorInfoWrapper = styled.div`
  text-align: left;
  ${media.tabletPortrait} {
    text-align: center;
  }
`;

export const SlotSelectorTitleWrapper = styled(SlotSelectorInfoWrapper)`
  margin-bottom: ${rem(10)};
`;

export const InputLabel = styled.label`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.colorStack};
  width: 100%;
`;
