import LinkArea from '@components/atoms/LinkArea';
import Paragraph from '@components/atoms/Paragraph';
import media from '@constants/responsive';
import { sizeLookup } from '@styles/fontSizes';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: ${rem(16)};
  padding: ${rem(16)};
`;

export const StoreInfoText = styled(Paragraph)`
  ${sizeLookup['medium']}
  ${media.tabletPortrait} {
    ${sizeLookup['small']}
  }
`;

export const FlexLinkArea = styled(LinkArea)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${rem(4)};
`;

export const HiddenOnMobile = styled.div`
  display: none;

  ${media.desktop} {
    display: block;
  }
`;

export const HiddenOnDesktop = styled.div`
  display: block;

  ${media.desktop} {
    display: none;
  }
`;
