import Heading from '@components/atoms/Heading';
import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/Paragraph';
import AddressFieldWithSuggestions from '@components/organisms/AddressFieldWithSuggestions/AddressFieldWithSuggestions';
import useOpenDeliveryModal from '@components/organisms/Header/hooks/useOpenDeliveryModal/useOpenDeliveryModal';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import useMostLikelyAddress from '@hooks/useMostLikelyAddress/useMostLikelyAddress';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useResponsive from '@hooks/useResponsive';
import IconArrowRight from '@public/icons/regularIcons/icon-arrow-right.svg';
import { selectCartDeliveryModeCode } from '@selectors/cart';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DeliveryAddressForm,
  DeliveryBannerWrapper,
  DeliverytextContent,
  GoToDeliveryModalButton,
} from './DeliveryBanner.styles';

interface FormValues {
  street: string;
}
const DeliveryBanner = () => {
  const { openDeliveryModalWithAddress } = useOpenDeliveryModal();
  const { t } = useTranslation('delivery');
  const deliveryMode = useAppSelector(selectCartDeliveryModeCode);
  const { isDesktopOrGreater } = useResponsive();
  const { data: address } = useMostLikelyAddress();
  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { hasFetchedCustomer } = useCustomer();
  const { register, watch, setValue, handleSubmit, getValues } = useForm<FormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (hasFetchedCustomer && !!address?.line1) {
      const streetValues = `${address?.line1} ${address.postalCode} ${address.town}` || '';
      setValue('street', streetValues);
    }
  }, [hasFetchedCustomer, address, setValue]);

  useOnClickOutside(containerRef, () => {
    setAutocompleteIsOpen(false);
  });

  if (deliveryMode || !isDesktopOrGreater) {
    return null;
  }
  const handleFormKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter' && autocompleteIsOpen) {
      e.preventDefault();
    }
  };
  const onClickAutoComplete = (theChoosenOne: Suggestion) => {
    setValue(
      'street',
      `${theChoosenOne.street} ${theChoosenOne.street_number}${theChoosenOne.letter} ${theChoosenOne.postalcode} ${theChoosenOne.locality}`
    );
  };

  const onSubmit = () => {
    setAutocompleteIsOpen(false);
  };

  return (
    <DeliveryBannerWrapper>
      <DeliverytextContent>
        <Heading type="h3">{t('delivery->deliveryBanner->bannerTitle')}</Heading>
        <Paragraph size="small">{t('delivery->deliveryBanner->bannerText')}</Paragraph>
      </DeliverytextContent>
      <div ref={containerRef}>
        <DeliveryAddressForm onSubmit={handleSubmit(onSubmit)} onKeyDown={handleFormKeyDown}>
          <AddressFieldWithSuggestions
            watch={watch}
            setValue={setValue}
            streetId="street"
            register={register}
            onClickAutoComplete={onClickAutoComplete}
            setAutocompleteIsOpen={setAutocompleteIsOpen}
            autoCompleteIsOpen={autocompleteIsOpen}
            label={!address?.line1 ? t('delivery->deliveryBanner->addressFieldPlaceholder') : ''}
          />

          <GoToDeliveryModalButton onClick={() => openDeliveryModalWithAddress(getValues('street'))}>
            {t('delivery->deliveryBanner->show')} <Icon icon={IconArrowRight} color="white" />
          </GoToDeliveryModalButton>
        </DeliveryAddressForm>
      </div>
    </DeliveryBannerWrapper>
  );
};

export default DeliveryBanner;
