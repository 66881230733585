import { SideNavNode } from '@features/sideNav/useSideNavData/useSideNavData';
import useCmsComponents from '@hooks/useCmsComponentGroup';

const mapNodes = (nodes: any[]): SideNavNode[] => {
  return nodes.map((node) => mapNode(node));
};

const mapId = (id: any, title: string) => {
  return [id, title].map((s) => s.trim()).join(' | ');
};

const mapNode = (node: any): SideNavNode => {
  const {
    url,
    linkName: {
      entry: [{ value: title }],
    },
  } = node;
  return {
    id: mapId(title, url),
    title,
    url,
    trackingId: title,
    parentIds: [],
    childNodes: [],
  };
};

const leftMenuComponent = 'LeftMenuComponent';

const useCmsLinks = () => {
  const { data } = useCmsComponents(JSON.stringify(leftMenuComponent));
  return data?.[0].navigationNode?.links ? mapNodes(data[0].navigationNode.links) : [];
};

export default useCmsLinks;
