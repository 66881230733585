/* eslint-disable jsx-a11y/click-events-have-key-events */
import ErrorMessage from '@components/atoms/ErrorMessage';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useRef } from 'react';
import {
  ErrorMessageStyles,
  InfoMessageWrapper,
  SubMessageLinkStyles,
  SubMessageWrapper,
} from './DeliveryHomeSelector.styles';

interface SubMessageProps {
  onRedirect: () => void;
}

export const SubMessage = ({ onRedirect }: SubMessageProps) => {
  const { t } = useTranslation('delivery');
  return (
    <SubMessageWrapper>
      <Paragraph size="md" textColor="secondary">
        <span data-testid="error-pickup-in-store-info">{t('delivery->home->infoMessages->error->pickupInStore')}</span>
        <SubMessageLinkStyles onClick={onRedirect} data-testid="error-pickup-in-store-link">
          {t('delivery->home->links->here')}
        </SubMessageLinkStyles>
      </Paragraph>
    </SubMessageWrapper>
  );
};

export const INVALID_ADDRESS_ERROR_MESSAGE = 'invalidAddress';
export const AREA_NOT_AVAILABLE_ERROR_MESSAGE = 'areaNotAvailable';
export type ErrorType = typeof INVALID_ADDRESS_ERROR_MESSAGE | typeof AREA_NOT_AVAILABLE_ERROR_MESSAGE;

interface ErrorInfoProps {
  onRedirect: () => void;
  error: ErrorType;
}

const ErrorInfo = ({ onRedirect, error = AREA_NOT_AVAILABLE_ERROR_MESSAGE }: ErrorInfoProps) => {
  const { t } = useTranslation('delivery');
  const errorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorRef?.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorRef]);
  return (
    <InfoMessageWrapper ref={errorRef}>
      <ErrorMessageStyles data-testid="error-message">
        <ErrorMessage errorMessage={t(`delivery->home->infoMessages->error->${error}`)} />
        {error === AREA_NOT_AVAILABLE_ERROR_MESSAGE && <SubMessage onRedirect={onRedirect} />}
      </ErrorMessageStyles>
    </InfoMessageWrapper>
  );
};

export default React.memo(ErrorInfo);
