/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { AddressData } from '@api/generated/storefront';
import getDeliverabilityStatus from '@api/getDeliverabilityStatus';
import getDeliverabilityWithGeposit from '@api/getDeliverabilityWithGeposit';
import getFullAddressSuggestions from '@api/getFullAddressSuggestions';
import isAddressValid from '@api/isAddressValid';
import {
  AREA_NOT_AVAILABLE_ERROR_MESSAGE,
  INVALID_ADDRESS_ERROR_MESSAGE,
} from '@components/molecules/DeliveryHomeSelector/ErrorInfo';
import { formatAddressPlain } from '@helpers/address';
import debounce from '@helpers/debounce';
import { CancelToken } from 'axios';

export const concatAddress = ({ line1 = '', postalCode = '', town = '' }: AddressData) => {
  return formatAddressPlain(line1, postalCode, town);
};

export const getFormattedAddress = (address: AddressData) => {
  return { formattedAddress: concatAddress(address), address };
};

export const validateAddress = async (fullAddress: string, cancelToken: CancelToken) => {
  try {
    const res = await isAddressValid(fullAddress, cancelToken);
    const { response: { is_valid: isValid = 0, match = undefined } = {} } = res.data;
    if (!isValid || !match) {
      return Promise.reject(new Error(INVALID_ADDRESS_ERROR_MESSAGE));
    }
    return Promise.resolve(match);
  } catch {
    return Promise.reject(new Error(INVALID_ADDRESS_ERROR_MESSAGE));
  }
};

export const zipCodeHasSlots = async (code: string, isB2B: boolean, cancelToken: CancelToken) => {
  try {
    const data = {
      code,
      b2b: isB2B,
    };
    const response = await getDeliverabilityStatus(data, cancelToken);
    const hasAvailableSlots = response?.data.deliverable;
    if (!hasAvailableSlots) {
      return Promise.reject(new Error(AREA_NOT_AVAILABLE_ERROR_MESSAGE));
    }
    return Promise.resolve(response.data);
  } catch {
    return Promise.reject(new Error(AREA_NOT_AVAILABLE_ERROR_MESSAGE));
  }
};

export const checkDeliverabilityWithGeposit = async (fullAddress: string, cancelToken?: CancelToken) => {
  try {
    const response = await getDeliverabilityWithGeposit(fullAddress, cancelToken);
    const { isDeliveryAvailable } = response.data;
    if (!isDeliveryAvailable) {
      return Promise.reject(new Error(AREA_NOT_AVAILABLE_ERROR_MESSAGE));
    }
    return Promise.resolve();
  } catch {
    return Promise.reject(new Error(AREA_NOT_AVAILABLE_ERROR_MESSAGE));
  }
};

const fetchAddressSuggestions = async (address: string, cancelToken: CancelToken) => {
  const data = {
    address,
    rows: '20',
  };
  try {
    const {
      data: { suggestions },
    } = await getFullAddressSuggestions(data, cancelToken);
    return Promise.resolve(suggestions);
  } catch (error) {
    return Promise.reject(new Error('Could not fetch street suggestions'));
  }
};

export const debouncedfetchAddressSuggestions = debounce(fetchAddressSuggestions, 500);

export const buildCustomerSessionUpdateData = ({ address, store }: any) => ({
  promotionStoreId: store.storeId,
});
