import { StoreContactInfo } from '@features/storepage/types';
import IconMail from '@public/icons/regularIcons/icon-mail.svg';
import IconPhone from '@public/icons/regularIcons/icon-phone.svg';
import useTranslation from 'next-translate/useTranslation';
import LinkWithIcon from '../LinkWithIcon';

const Contact = ({ contactInfo }: { contactInfo: StoreContactInfo }) => {
  const { t } = useTranslation('store');
  const { phoneNumber, email } = contactInfo;

  return (
    <>
      {phoneNumber && <LinkWithIcon href={`tel:${phoneNumber}`} icon={IconPhone} text={phoneNumber} />}
      {email && <LinkWithIcon href={`mailto:${email}`} icon={IconMail} text={t('info->email')} />}
    </>
  );
};

export default Contact;
