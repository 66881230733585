import { rem } from 'polished';
import styled from 'styled-components';

export const Summary = styled.summary`
  list-style: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${rem(16)} 0;

  &::marker,
  &::-webkit-details-marker {
    display: none;
  }
`;

export const Details = styled.details`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorSilver};

  &[open] summary svg {
    transform: rotate(180deg);
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const Content = styled.div`
  padding-bottom: ${rem(16)};
`;
