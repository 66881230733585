import { Stack } from '@components/atoms/Stack';
import { StoreOpeningHours } from '@features/storepage/types';
import useTranslation from 'next-translate/useTranslation';
import { StoreInfoText } from '../StoreInfo.styles';
import { OpeningHoursWrapper } from './OpeningsHours.styles';

const OpeningHours = ({
  name,
  openToClosingHours,
  color = 'black',
}: {
  name?: string;
  openToClosingHours?: string;
  color?: 'primary' | 'black';
}) => {
  if (!name) return null;
  return (
    <OpeningHoursWrapper>
      <StoreInfoText color={color}>{name}</StoreInfoText>
      {openToClosingHours && <StoreInfoText color={color}>{openToClosingHours}</StoreInfoText>}
    </OpeningHoursWrapper>
  );
};

const OpeningHoursContent = ({ storeOpeningHours }: { storeOpeningHours: StoreOpeningHours }) => {
  const { openingDays = [], storeFeatureOpeningDays = [], specialOpeningDays = [] } = storeOpeningHours || {};
  const { t } = useTranslation('store');

  return (
    <Stack $space="nano">
      {openingDays?.length ? (
        <div>
          {openingDays?.map(({ name, openToClosingHours }) => {
            return <OpeningHours key={openToClosingHours} name={name} openToClosingHours={openToClosingHours} />;
          })}
        </div>
      ) : null}
      {storeFeatureOpeningDays?.map(({ storeFeature, openingHours }) => (
        <div key={storeFeature}>
          <StoreInfoText weight="medium">{storeFeature}</StoreInfoText>
          {openingHours.map(({ name, openToClosingHours }) => (
            <OpeningHours key={name} name={name} openToClosingHours={openToClosingHours} />
          ))}
        </div>
      ))}

      {!!specialOpeningDays?.length && (
        <div key={'specialOpeningHours'}>
          <StoreInfoText weight="medium" color="primary">
            {t('info->specialOpeningHours')}
          </StoreInfoText>
          {specialOpeningDays?.map(({ name, openToClosingHours }) => (
            <OpeningHours
              key={`specialOpeningHours-${name}`}
              name={name}
              openToClosingHours={openToClosingHours}
              color="primary"
            />
          ))}
        </div>
      )}
    </Stack>
  );
};

export default OpeningHoursContent;
