import { PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import CmsContentSlot from '@components/atoms/CmsContentSlot/CmsContentSlot';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import React from 'react';

interface Props {
  cmsPage: PageWithSlots;
  children?: React.ReactNode;
  slotList: string[];
}

// TODO: This currently is only showing cms components placed in slot "TopWide" and "MainContent". We might need to add more slots later
const DynamicMasterTemplate = ({ cmsPage, children, slotList }: Props) => {
  const { TopWide, MainContent } = collectSlots(cmsPage.slots, slotList);
  const topWideSlotList = TopWide?.component || [];
  const mainContentSlotList = MainContent?.component || [];

  return (
    <>
      <CmsContentSlot
        slotData={TopWide}
        id="Content-topWideSlot-hemkop-DynamicTemplatepage"
        data-testid="Content-topWideSlot-hemkop-DynamicTemplatepage"
      >
        {topWideSlotList.map((component: CmsComponent, index) => (
          <CmsComponent key={component.uuid} component={component} slotName="TopWide" slotPosition={index} />
        ))}
      </CmsContentSlot>
      {children && children}
      <CmsContentSlot
        slotData={TopWide}
        id="Content-mainContentSlot-hemkop-DynamicTemplatepage"
        data-testid="Content-mainContentSlot-hemkop-DynamicTemplatepage"
      >
        {mainContentSlotList.map((component: CmsComponent, index) => (
          <CmsComponent key={component.uuid} component={component} slotName="MainContent" slotPosition={index} />
        ))}
      </CmsContentSlot>
    </>
  );
};

export default DynamicMasterTemplate;
