import HeaderButton from '@components/atoms/HeaderButton';
import Icon from '@components/atoms/Icon';
import Overlay from '@components/atoms/Overlay';
import { handleSymplifyNewPage } from '@features/ABTesting/useSymplifyABTesting';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useResponsive from '@hooks/useResponsive';
import IconClose from '@public/icons/regularIcons/icon-close-thin.svg';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useState } from 'react';
import { selectSideNavIsOpen, sideNavToggled } from 'redux/slices/sideNav';
import RecursiveList from './RecursiveList/RecursiveList';
import { ButtonWrapper, Divider, Menu, MenuHeaderWrapper, MenuWrapper, TextContainer } from './SideNav.styles';
import useSideNavData from './useSideNavData';
import { SideNavNode } from './useSideNavData/useSideNavData';

const SideNav = () => {
  const dispatch = useAppDispatch();
  const { pageHasSideNav, links, selectedId, getNode, assortment } = useSideNavData();
  // keeps track of which categories that should be expanded
  const [activeItems, setActiveItems] = useState<string[]>([]);
  // keeps track of which list that should have a active border
  const [currentlyExpandedCategory, setCurrentlyExpandedCategory] = useState('');
  const [hasSentSymplifyEvent, setHasSentSymplifyEvent] = useState(false);
  const hasSideNavData = links?.length > 0 || assortment?.length > 0;
  const sideNavIsOpen = useAppSelector(selectSideNavIsOpen);
  const { isDesktopOrGreater } = useResponsive();
  const isMobile = !isDesktopOrGreater;
  const { t } = useTranslation('sidenav');

  const handleCloseSideNav = useCallback(() => {
    dispatch(sideNavToggled(false));
  }, [dispatch]);

  const onClickExpand = (parentIds: string[], id: string) => {
    setActiveItems(parentIds);
    setCurrentlyExpandedCategory(id);
  };

  const setInitialStatesFromSelectedNode = () => {
    if (selectedId) {
      const selectedNode = getNode(selectedId) as SideNavNode;
      const initialState = (selectedNode && [...selectedNode.parentIds, selectedNode.id]) || [];
      setActiveItems(initialState);
      const hasChildren = selectedNode.childNodes.length > 0;
      const lastParent = selectedNode.parentIds[selectedNode.parentIds.length - 1];
      const currentlyExpanded = hasChildren ? selectedId : lastParent;
      currentlyExpanded && setCurrentlyExpandedCategory(currentlyExpanded);
    }
  };

  useEffect(() => {
    setInitialStatesFromSelectedNode();
  }, [selectedId]);

  useEffect(() => {
    if (pageHasSideNav && hasSideNavData && !hasSentSymplifyEvent) {
      handleSymplifyNewPage();
      setHasSentSymplifyEvent(true);
    }
  }, [pageHasSideNav, hasSideNavData, hasSentSymplifyEvent]);

  const shouldRenderMobileSideNav = sideNavIsOpen && isMobile;
  if (!pageHasSideNav) {
    return null;
  }

  return (
    <>
      <MenuWrapper $shouldOpenSideNav={sideNavIsOpen} data-testid="SideNav" id="sideNav">
        <MenuHeaderWrapper $shouldOpenSideNav={sideNavIsOpen}>
          {shouldRenderMobileSideNav && (
            <>
              <TextContainer $opacity={false} $indentationLvl={0} as="div">
                <Heading type="h2" color="black">
                  {t('sidenav->sectionHeaders->assortment')}
                </Heading>
              </TextContainer>
              <ButtonWrapper>
                <HeaderButton
                  aria-label={t('common:defaultActions->close')}
                  onClick={handleCloseSideNav}
                  variant="secondary"
                >
                  <Icon icon={IconClose} size={20} />
                </HeaderButton>
              </ButtonWrapper>
            </>
          )}
        </MenuHeaderWrapper>

        <Menu data-testid="cms-links">
          {links.map((linkNode) => (
            <RecursiveList
              link={linkNode}
              key={linkNode.id}
              onClick={onClickExpand}
              activeItems={activeItems}
              currentlyExpandedCategory={currentlyExpandedCategory}
              selectedId={selectedId || ''}
            />
          ))}
        </Menu>

        <Divider />

        <Menu data-testid="assortment-links">
          <TextContainer $opacity={true} $indentationLvl={0} as="li">
            {t('sidenav->sectionHeaders->categories')}
          </TextContainer>
          {assortment.map((linkNode) => (
            <RecursiveList
              link={linkNode}
              key={linkNode.id}
              onClick={onClickExpand}
              activeItems={activeItems}
              currentlyExpandedCategory={currentlyExpandedCategory}
              selectedId={selectedId || ''}
            />
          ))}
        </Menu>
      </MenuWrapper>
      <>
        {shouldRenderMobileSideNav && (
          <Overlay opacity={0.5} onClick={handleCloseSideNav} zIndex={99} lockScroll={true} />
        )}
      </>
    </>
  );
};

export default SideNav;
